import { template as template_2e13ea0ef4b64a85aefd6aeb79cbd088 } from "@ember/template-compiler";
const FKText = template_2e13ea0ef4b64a85aefd6aeb79cbd088(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
